import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import Poses from "../components/Poses";
import HeroBanner from "../components/HeroBanner";
import SearchPoses from "../components/SearchPoses";

function Home() {
  const [poses, setPoses] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const fetchInitialPoses = async () => {
      try {
        const response = await fetch("/merged-yoga-poses.json");
        const data = await response.json();
        setPoses(data.yoga_poses); // Sets the initial poses to be displayed on the page.
      } catch (error) {
        console.log(error);
      }
    };
    fetchInitialPoses();
  }, []);

  // Passes state down to components. Could use Context API here if structure expands.

  return (
    <Box>
      <HeroBanner />
      <SearchPoses
        setCategory={setCategory}
        poses={poses}
        setPoses={setPoses}
        category={category}
      />
      <Poses setPoses={setPoses} poses={poses} category={category} />
    </Box>
  );
}

export default Home;
